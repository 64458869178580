const regExp = /\[(.*?)\]/;

const FormValidation = {
    install(Vue) {
        Object.defineProperty(Vue.prototype, "$formValidation", {
            get() {
                return {
                    isValidationRpcResponse(error) {
                        return error.code === -32605;
                    },
                    parse(messages) {
                        const result = {};
                        messages.forEach((error) => {
                            if (!error.field) {
                                return;
                            }
                            const match = error.field.match(regExp);
                            if (!match || !match[1]) {
                                return;
                            }
                            const field = match[1];
                            if (!result[field]) {
                                result[field] = [];
                            }
                            result[field].push(error.message);
                        });

                        return result;
                    },
                }
            },
        });
    },
};

export default FormValidation