import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        redirect: "loans",
        component: {template: "<router-view></router-view>"},
        meta: {auth: true, title: "Главная"},
        children: [
            {
                path: "loans",
                component: {template: "<router-view></router-view>"},
                redirect: "loans/my",
                meta: {
                    auth: [
                        "admin",
                        "senior_collector",
                        "collector",
                    ],
                    title: "Займы",
                },
                children: [
                    {
                        path: "my",
                        component: () => import("./../components/Admin/Loans/My"),
                        meta: {
                            auth: [
                                "admin",
                                "senior_collector",
                                "collector",
                            ],
                            title: "Список моих займов",
                        },
                    },
                    {
                        path: "all",
                        component: () => import("./../components/Admin/Loans/All"),
                        meta: {
                            auth: [
                                "admin",
                                "senior_collector",
                            ],
                            title: "Список всех займов",
                        },
                    },
                    {
                        path: ":id",
                        name: "loans.view",
                        meta: {
                            auth: [
                                "admin",
                                "senior_collector",
                                "collector",
                            ],
                            title: "Займ",
                            exclude_from_navigation: true,
                        },
                        component: () => import("./../components/Admin/Loans/Loan"),
                    },
                ],
            },
            {
                path: "payments",
                component: {template: "<router-view></router-view>"},
                redirect: "payments/list",
                meta: {
                    auth: [
                        "admin",
                        "senior_collector",
                        "collector",
                    ],
                    title: "Платежи",
                    without_children: true,
                },
                children: [
                    {
                        path: "list",
                        component: () => import("./../components/Admin/Payments/List"),
                        meta: {
                            auth: [
                                "admin",
                                "senior_collector",
                                "collector",
                            ],
                            title: "Список",
                        },
                    },
                ],
            },
            // {
            //     path: "statistics",
            //     component: () => import("./../components/Statistics"),
            //     meta: {auth: ["admin", "senior_collector"], title: "Статистика"},
            // },
            // {
            //     path: "analytics",
            //     component: () => import("./../components/Analytics"),
            //     meta: {auth: ["admin"], title: "Аналитика"},
            // },
            // {
            //     path: "export",
            //     component: {template: "<router-view></router-view>"},
            //     meta: {auth: ["admin"], title: "Выгрузки"},
            //     redirect: "export/collectors",
            //     children: [
            //         {
            //             path: "collectors",
            //             component: () => import("./../components/Export/Collectors"),
            //             meta: {auth: ["admin"], title: "Коллекторы"},
            //         },
            //         {
            //             path: "loans",
            //             component: () => import("./../components/Export/Loans"),
            //             meta: {auth: ["admin"], title: "Займы"},
            //         },
            //         {
            //             path: "payments",
            //             component: () => import("./../components/Export/Payments"),
            //             meta: {auth: ["admin"], title: "Платежи"},
            //         },
            //     ],
            // },
            {
                path: "admin",
                component: {template: "<router-view></router-view>"},
                redirect: "admin/users",
                meta: {auth: ["admin", "senior_collector"], title: "Администрирование"},
                children: [
                    {
                        path: "users",
                        component: {template: "<router-view></router-view>"},
                        redirect: "users/list",
                        meta: {auth: ["admin", "senior_collector"], title: "Пользователи"},
                        children: [
                            {
                                path: "list",
                                component: () => import("./../components/Admin/Users/List"),
                                meta: {auth: ["admin", "senior_collector"], title: "Список"},
                            },
                            {
                                path: "add",
                                component: () => import("./../components/Admin/Users/Add"),
                                meta: {auth: ["admin", "senior_collector"], title: "Добавление"},
                            },
                            {
                                path: ":id",
                                component: {template: "<router-view></router-view>"},
                                redirect: ":id/view",
                                meta: {auth: ["admin", "senior_collector"], title: "Пользователь"},
                                children: [
                                    {
                                        path: "view",
                                        component: () => import("./../components/Admin/Users/View"),
                                        meta: {auth: ["admin", "senior_collector"], title: "Просмотр"},
                                    },
                                    {
                                        path: "edit",
                                        component: () => import("./../components/Admin/Users/Edit"),
                                        meta: {auth: ["admin", "senior_collector"], title: "Редактирование"},
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "stages",
                        component: {template: "<router-view></router-view>"},
                        redirect: "stages/list",
                        meta: {auth: ["admin", "senior_collector"], title: "Стадии"},
                        children: [
                            {
                                path: "list",
                                component: () => import("./../components/Admin/Stages/List"),
                                meta: {auth: ["admin", "senior_collector"], title: "Список"},
                            },
                            {
                                path: "add",
                                component: () => import("./../components/Admin/Stages/Add"),
                                meta: {auth: "admin", title: "Добавление"},
                            },
                            {
                                path: ":id",
                                component: {template: "<router-view></router-view>"},
                                redirect: ":id/view",
                                meta: {auth: ["admin", "senior_collector"], title: "Стадия"},
                                children: [
                                    {
                                        path: "view",
                                        component: () => import("./../components/Admin/Stages/View"),
                                        meta: {auth: ["admin", "senior_collector"], title: "Просмотр"},
                                    },
                                    {
                                        path: "edit",
                                        component: () => import("./../components/Admin/Stages/Edit"),
                                        meta: {auth: "admin", title: "Редактирование"},
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "templates-sms",
                        component: {template: "<router-view></router-view>"},
                        redirect: "templates-sms/list",
                        meta: {auth: ["admin", "senior_collector"], title: "Шаблоны смс"},
                        children: [
                            {
                                path: "list",
                                component: () => import("./../components/Admin/TemplatesSms/List"),
                                meta: {auth: ["admin", "senior_collector"], title: "Список"},
                            },
                            {
                                path: "add",
                                component: () => import("./../components/Admin/TemplatesSms/Add"),
                                meta: {auth: "admin", title: "Добавление"},
                            },
                            {
                                path: ":id",
                                component: {template: "<router-view></router-view>"},
                                redirect: ":id/view",
                                meta: {auth: ["admin", "senior_collector"], title: "Стадия"},
                                children: [
                                    {
                                        path: "edit",
                                        component: () => import("./../components/Admin/TemplatesSms/Edit"),
                                        meta: {auth: "admin", title: "Редактирование"},
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    {
        path: "/login",
        component: () => import("@/components/Auth/Login"),
        meta: {
            auth: false,
        },
    },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router
