import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        socket: null,
        syncAlertInstance: null,
        footerNotifier: false,
        footerMessage: "",
        events: {
            "collector/blocking_process": true, // на самом деле это поле отображает отсутствие блокирующего процесса
        },
    },
    getters: {
        socket(state) {
            return state.socket;
        },
        footerNotifier(state) {
            return state.footerNotifier;
        },
        footerMessage(state) {
            return state.footerMessage;
        },
        "collector/blocking_process"(state) {
            return state.events["collector/blocking_process"];
        },
    },
    mutations: {
        createSocketConnection(state, payload) {
            state.socket = payload;
        },
        createSyncAlertInstance(state, payload) {
            if (!state.syncAlertInstance) {
                state.syncAlertInstance = payload.instance;
                state.syncAlertInstance.ondismiss = () => {
                    state.syncAlertInstance = null;

                    if (!state.events["collector/blocking_process"]) {
                        state.footerNotifier = true;
                        state.footerMessage = payload.message;
                    }
                };
            }
        },
        clearSocketConnection(state) {
            if (state.socket) {
                state.socket.close(1000);
                state.socket = null;
            }
        },
        collectorEvents(state, payload) {
            state.events[payload.key] = payload.signValue;
        },
        dissmissSyncAlertInstance(state) {
            if (state.syncAlertInstance) {
                state.syncAlertInstance.dismiss();
                state.syncAlertInstance = null;
            }
            state.footerNotifier = false;
            state.footerMessage = "";
        },
    },
    actions: {
        createSocketConnection({ commit }, payload) {
            commit("createSocketConnection", payload);
        },
        createSyncAlertInstance({ commit }, payload) {
            commit("createSyncAlertInstance", payload);
        },
        clearSocketConnection({ commit }) {
            commit("clearSocketConnection");
        },
        collectorEvents({ commit }, payload) {
            commit("collectorEvents", payload);
        },
        dissmissSyncAlertInstance({ commit }) {
            commit("dissmissSyncAlertInstance");
        },
    },
});
