<template>
    <div class="footer">
        <span>{{ footerMessage }}</span>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
            footerMessage: "footerMessage",
        }),
    },
}
</script>

<style lang="scss">
.footer {
  text-align: center;
  background: #FFEEBA;
  padding: 10px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer > span {
    color: #FFFFFF;
    background: linear-gradient(to right,#212529 10%, #9da413 50%, #212529 60%);
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 3s linear infinite reverse;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
</style>