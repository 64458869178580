import {v4 as uuidv4} from "uuid"

const JsonRpc = {
    install(Vue) {
        Object.defineProperty(Vue.prototype, "jsonRpc", {
            get: function get() {
                return function (method, params, noAlertDismiss = false) {
                    return new Promise((resolve, reject) => {
                        const error = (response, code, message, data) => {
                            reject({
                                response,
                                error: {
                                    code,
                                    message,
                                    data,
                                },
                            });
                        };
                        const requestId = uuidv4();
                        Vue.axios({
                            method: "post",
                            url: "/rpc/v1",
                            data: {
                                jsonrpc: "2.0",
                                id: requestId,
                                method,
                                params,
                            },
                        }).then(async (response) => {
                            if (response.status !== 200) {
                                error(response, 0, "Некорректный статус ответа");
                                return;
                            }

                            const data = response.data;
                            let notification;

                            if (!data.jsonrpc
                                || data.jsonrpc !== "2.0"
                                || !data.id
                                || data.id !== requestId
                                || (!data.result && !data.error)
                                || (!!data.result && !!data.error)
                            ) {
                                resolve(data);
                                return;
                            }

                            if (data.error) {
                                if (this.$alertify) {
                                    if (this.$alertify) {
                                        if (noAlertDismiss) {
                                            this.$alertify.error(data.error.message, 0);
                                        } else {
                                            this.$alertify.error(data.error.message);
                                        }
                                    }
                                }
                                error(response, data.error.code, data.error.message, data.error.data);

                                return;
                            } else if (data.result && data.result.message) {
                                if (this.$alertify) {
                                    const message = data.result.message;

                                    if (method === "admin.getReBalanceAvailability") {
                                        this.$store.dispatch("collectorEvents", {
                                            key: "collector/blocking_process",
                                            signValue: data.result.reBalanceAvailability,
                                        });
                                        this.$store.dispatch("createSyncAlertInstance", {
                                            instance: this.$alertify.warning(message, 0),
                                            message,
                                        });
                                    } else {
                                        if (noAlertDismiss) {
                                            notification = this.$alertify.success(message, 0);
                                        } else {
                                            this.$alertify.success(message);
                                        }
                                    }
                                }
                            }
      
                            noAlertDismiss ? resolve({
                                result: data.result,
                                notification,
                            }) : resolve(data.result);
                        }).catch((e) => {
                            return error(e.response, 0, "Ошибка при выполнении запроса на сервер");
                        })
                    })
                };
            },
        });
    },
};

export default JsonRpc
