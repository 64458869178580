import { mapGetters, mapActions } from "vuex";

export default {
    computed: {
        ...mapGetters({
            socket: "socket",
            syncAlertInstance: "syncAlertInstance",
        }),
    },
    methods: {
        ...mapActions([
            "createSocketConnection",
            "createSyncAlertInstance",
            "clearSocketConnection",
            "collectorEvents",
            "dissmissSyncAlertInstance",
        ]),
        socketChannelRegister(collectorId) {
            if (this.socket) {
                return;
            }

            this.createSocketConnection(
                new WebSocket(`
                wss://${window.env.VUE_APP_CHANNEL}/ws/collector/${collectorId}?access_token=${window.env.VUE_APP_CHANNEL_TOKEN}
            `),
            );

            this.socket.onmessage = event => {
                const data = JSON.parse(event.data);
                const sign = Object.keys(data.result).filter(
                    key => key !== "message",
                )[0];
                const signValue = data.result[sign];
                const outcome = data.result.success;
                const message = data.result.message;

                this.collectorEvents({
                    key: data.key,
                    signValue,
                });

                if (data.key === "collector/blocking_process") {
                    this.flushBlockingProcess(signValue, message, outcome);
                } else {
                    outcome
                        ? this.$alertify.success(message)
                        : this.$alertify.warning(message);
                }
            };

            this.socket.onerror = error => {
                this.$alertify.error(error.message);
            };
        },
        socketChannelDisconnect() {
            this.clearSocketConnection();
        },
        flushBlockingProcess(signValue, message, outcome) {
            if (!this.$auth.check(["admin", "senior_collector"])) {
                return;
            }

            if (!signValue) {
                this.createSyncAlertInstance({
                    instance: this.$alertify.warning(message, 0),
                    message,
                });
            } else {
                this.dissmissSyncAlertInstance();

                if (outcome) {
                    this.$alertify.success(message);
                } else {
                    this.$alertify.error(message);
                }
            }
        },
    },
};
