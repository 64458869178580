<template>
    <div>
        <navigation v-if="$auth.ready() && $auth.check()" />
        <bread-crumbs v-if="$auth.ready() && $auth.check()" />
        <b-container
            id="app"
            fluid
        >
            <b-row
                v-if="!$auth.ready()"
                class="justify-content-center"
            >
                <b-overlay
                    :show="true"
                    :opacity="0.5"
                >
                    <b-col
                        class="text-center p-4"
                    >
                        Ожидайте, идет загрузка приложения
                    </b-col>
                </b-overlay>
            </b-row>
            <b-row
                v-if="$auth.ready()"
                fluid
            >
                <b-col>
                    <router-view />
                </b-col>
            </b-row>
        </b-container>
        <footer-notifier v-if="footerNotifier" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import socketChannel from "@/mixins/socket-channel";
import BreadCrumbs from "./components/BreadCrumbs";
import Navigation from "./components/Navigation";
import FooterNotifier from "./components/FooterNotifier.vue";

export default {
    components: {Navigation, BreadCrumbs, FooterNotifier},
    mixins: [socketChannel],
    computed: {
        ...mapGetters({
            footerNotifier: "footerNotifier",
        }),
        user() {
            return this.$auth.user() || {};
        },
    },
    beforeMount() {
        const unwatch = this.$watch("user", (value) => {
            const id = value.id;

            this.socketChannelRegister(id);
            unwatch();
        });
    },
};
</script>
